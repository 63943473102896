export default function TextInputField({setSource, title, hint, placeholder, secret=false}) {
    return (
        <div className={'flex justify-between items-center w-full max-lg:flex-col max-lg:items-start max-lg:gap-2'}>
            <div>
                <p className={'text-lg'}>{title}</p>
                <p className={'text-sm text-white'}>{hint}</p>
            </div>
            <div className={'max-lg:w-full'}>
                <input type={(secret ? 'password' : 'text')} className={'p-2 ps-5 pe-5 bg-white w-[300px] max-lg:w-full outline-none rounded-lg transition-all'} placeholder={placeholder} onInput={(e) => {setSource(e.target.value)}} />
            </div>
        </div>
    )
}