import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import TextInputField from "../components/TextInputField";
import axiosInstance from "../api/axiosInstance";
import axios from "axios";
import ActivatedButton from "../components/ActivatedButton";
import Button from "../components/Button";
import Headmenu from "../components/headmenu";

export default function OwnerApplicationSearch() {

    const [applicationId, setApplicationId] = useState('')


    return (
        <>
            <Headmenu/>
            <div className={'container m-auto'}>

                <div className={'p-20 bg-[#B2B2B2] rounded-lg w-1/2 max-lg:w-full max-lg:p-5 m-auto mt-20'}>
                    <h2 className={'text-3xl mb-5'}>Поиск заявки</h2>

                    <TextInputField hint={'Введите номер'} placeholder={'Номер'} secret={false}
                                    setSource={setApplicationId}
                                    title={'Номер'}/>

                    <div className={'mt-5 flex flex-col'}>
                        <Button onClick={() => {
                            window.location = '/application/' + applicationId
                        }}>Найти</Button>
                    </div>

                </div>
            </div>
        </>
    )
}