import {useEffect, useState} from "react";

export default function OptionsPickerField({setSource, choices}) {

    const [selectedValue, setSelectedValue] = useState([])

    const [isChoicesVisible, setIsChoicesVisible] = useState(false)

    const addValue = (val) => {
        setSelectedValue([...selectedValue, val])
    }

    const removeValue = (val) => {
        setSelectedValue(selectedValue.filter(item => item !== val))
    }

    useEffect(() => {
        setSource(selectedValue)
    }, [selectedValue]);

    return (
        <div className={'flex flex-col'}>
            {
                choices.map((item) =>
                    <div className={'flex justify-between items-center hover:bg-blue-100 transition-all p-5 cursor-pointer'} onClick={() => {
                            if (selectedValue.includes(item.name)) removeValue(item.name)
                            else addValue(item.name)
                        }}>
                        <p>{item.name}</p>
                        <div className={'text-3xl'}>
                            {
                                selectedValue.includes(item.name) ? (
                                    <i className='bx bxs-check-circle'></i>
                                ) : (
                                    <i className='bx bx-circle'></i>
                                )
                            }
                        </div>

                    </div>
                )
            }
        </div>
    )
}