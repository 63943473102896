import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import TextInputField from "../components/TextInputField";
import axiosInstance from "../api/axiosInstance";
import axios from "axios";
import ActivatedButton from "../components/ActivatedButton";
import Button from "../components/Button";
import Headmenu from "../components/headmenu";

export default function OwnerApplicationDetails() {

    const [token, setToken] = useState(null)
    const {applicationId} = useParams()
    const [acode, setAcode] = useState(null)

    const [applicationData, setApplicationData] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const [authenticated, setAuthenticated] = useState(false)


    useEffect(() => {
        axiosInstance.get(`applications/auth-details/${applicationId}/`)
            .then((response) => {
                setApplicationData(response.data)
                setAuthenticated(true)
            })
            .catch((error) => {
                setApplicationData(null)
                setAuthenticated(false)
                setToken(null)
                localStorage.removeItem('token')
                alert("Произошла ошибка или такой заявки не существует")
            })


    }, []);

    return (
        <>
            <Headmenu/>
            <div className={'container m-auto'}>
                {
                    (applicationData) && (
                        <div className={'m-auto w-1/2 max-lg:w-full'}>
                            <div className={'m-auto mt-10 border border-blue-500 bg-white p-20 max-lg:p-5'}>
                                <p className={'text-4xl text-blue-500 mb-5'}>Детали заявки №{applicationId}</p>
                                <div className={'flex flex-col gap-3'}>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Статус</b></p>
                                        <p>{applicationData.status.name}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Номер телефона</b></p>
                                        <p>{applicationData.nakdi_number}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Данные заявителя</b></p>
                                        <p>{applicationData.applicant_data}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Email</b></p>
                                        <p>{applicationData.email}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Номер телеофна</b></p>
                                        <p>{applicationData.phone}</p>
                                    </div>


                                    {
                                        applicationData.shipment_size ? (
                                            <div className={'flex justify-between items-center text-end'}>
                                                <p><b>Размер отправления</b></p>
                                                <p>{applicationData.shipment_size.name}</p>
                                            </div>
                                        ) : (
                                            <div className={'flex justify-between items-center text-end'}>
                                                <p><b>Кол-во свидетелей</b></p>
                                                <p>{applicationData.amount}</p>
                                            </div>
                                        )
                                    }


                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Гриф</b></p>
                                        <p>{applicationData.classification.name}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Дополнительные функции</b></p>
                                        <div>{
                                            applicationData.additional_functions.map((item) =>
                                                <p>{item.name}</p>
                                            )
                                        }</div>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Маршрут</b></p>
                                        <div>
                                            <p>{applicationData.from_address}</p>
                                            <i className='bx bx-down-arrow-alt'></i>
                                            <p>{applicationData.to_address}</p>
                                        </div>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Объект отправки</b></p>
                                        <div>{
                                            applicationData.shipment_types.map((item) =>
                                                <p>{item.name}</p>
                                            )
                                        }</div>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Планируемая дата</b></p>
                                        <div>{
                                            applicationData.plan_date ? (
                                                <p>{applicationData.plan_date}</p>
                                            ) : (
                                                <p>-</p>
                                            )
                                        }</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

            </div>
        </>
    )
}