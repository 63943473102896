import logo1 from '../media/logo1.png'
import logo2 from '../media/logo2.png'
import logo3 from '../media/logo3.png'
import logo4 from '../media/logo4.png'

export default function Headmenu() {
    return (
        <div className={'w-100 bg-[#061148] p-10 flex justify-between items-center max-lg:flex-col max-lg:gap-5'}>
            <a href={'/'}>
                <div className={'flex items-center gap-5'}>
                    <div className={'flex items-center gap-5'}>
                        <img src={logo1} className={'h-20'}/>
                        <img src={logo2} className={'h-16'}/>
                        <img src={logo3} className={'h-16'}/>
                        {/*<img src={logo4} className={'h-16 max-lg:hidden'}/>*/}
                        <></>
                    </div>
                </div>
            </a>

            <div className={'flex gap-5 max-lg:ms-4'}>
                <a href={"/auth"}><i className='bx bxs-user text-4xl text-white cursor-pointer'></i></a>
                <a href={"/application"}><i className='bx bx-search-alt text-4xl text-white cursor-pointer'></i></a>
            </div>
        </div>
    )
}