import {useState} from "react";

export default function SelectInputField({title, hint, setSource, availableChoices, multiChoice = false}) {

    const [selectedValue, setSelectedValue] = useState(null)

    const [isChoicesVisible, setIsChoicesVisible] = useState(false)

    return (
        <div className={'flex justify-between items-center w-full max-lg:flex-col max-lg:items-start max-lg:gap-2'}>
            <div>
                <p className={'text-lg'}>{title}</p>
                <p className={'text-sm text-white'}>{hint}</p>
            </div>
            <div className={'max-lg:w-full'}>
                <div className={'p-2 ps-5 pe-5 bg-white rounded-lg w-[300px] max-lg:w-full cursor-pointer transition-all'} onClick={() => setIsChoicesVisible(!isChoicesVisible)}>
                    {
                        selectedValue ? (
                            <>
                                {selectedValue}
                            </>
                        ) : (
                            <span className={'text-slate-400'}>
                                Выберите из списка
                            </span>
                        )
                    }
                </div>
                {
                    isChoicesVisible && (
                        <div className={'absolute z-10 p-0 bg-white mt-2 w-[300px] max-lg:w-2/3 rounded-lg flex flex-col shadow-xl'}>
                            {
                                availableChoices.map((item, ind) =>
                                    <div className={'p-2 hover:bg-slate-200 transition-all cursor-pointer w-full '  + (selectedValue === item.name ? 'bg-blue-200' : '')} onClick={() => {
                                        setSource(item.name)
                                        setSelectedValue(item.name)
                                        setIsChoicesVisible(false)
                                    }}>
                                        <p>{item.name}</p>
                                    </div>
                                )
                            }
                        </div>
                    )

                }
            </div>
        </div>
    )
}