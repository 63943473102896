import axios from "axios";

// Создаем экземпляр axios
const axiosInstance = axios.create({
    baseURL: "https://register.api.worldz.tech/api/",
});

// Перехватчик для добавления токена в каждый запрос
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Перехватчик для обработки ответа от сервера
axiosInstance.interceptors.response.use(
    (response) => {
        return response; // Если ответ успешный, просто возвращаем его
    },
    async (error) => {
        const originalRequest = error.config;

        // Проверка на 401 ошибку и наличие рефреш-токена
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = localStorage.getItem('refresh');
            if (refreshToken) {
                try {
                    // Запрос на обновление токена
                    const response = await axiosInstance.post('/token/refresh/', {
                        refresh: refreshToken,
                    });

                    // Сохранение нового access-токена
                    localStorage.setItem('access', response.data.access);

                    // Обновляем Authorization хедер и повторяем запрос
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                    originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;

                    return axiosInstance(originalRequest); // Повторяем запрос с обновленным токеном
                } catch (error) {
                    console.error('Не удалось обновить токен:', error);
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                }
            }
        }

        return Promise.reject(error); // Если ошибка другая, возвращаем её
    }
);

export default axiosInstance;
