import {useState} from "react";

export default function Accordion({title, children}) {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div onClick={() => setIsOpen(!isOpen)}
                 className={'flex justify-between items-center p-5 bg-white rounded-xl cursor-pointer'}>
                <p>{title}</p>
                {
                    isOpen ? (
                        <i className='bx bx-chevron-up text-xl'></i>
                    ) : (
                        <i className='bx bx-chevron-down text-xl'></i>
                    )
                }

            </div>

            {
                <div className={'p-5 bg-[#B2B2B2] rounded-xl mt-1 ' + (isOpen ? '' : 'hidden')}>
                    {children}
                </div>

            }

        </div>
    )
}