import {useEffect, useState} from "react";
import axiosInstance from "../api/axiosInstance";
import {useSearchParams} from "react-router-dom";
import Button from "../components/Button";
import ActivatedButton from "../components/ActivatedButton";
import Headmenu from "../components/headmenu";

export default function AdminPanelApplicationsList() {

    const [applicationsData, setApplicationsData] = useState([]);

    const statuses = {
        'Новые заявки': 'На рассмотрении',
        'Архив заявок': 'Выполнено',
    }

    const [searchParams] = useSearchParams();

    const [applicationStatuses, setApplicationStatuses] = useState(null)

    useEffect(() => {
        axiosInstance.get('statuses')
            .then((response) => {
                setApplicationStatuses(response.data)
            })
            .catch((error) => {
                console.error(error.response)
                alert("Возникла ошибка")
            })

    }, []);

    useEffect(() => {
        axiosInstance.get("applications/list?f=" + searchParams.get('fs'))
            .then((response) => {
                setApplicationsData(response.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }, [searchParams]);

    return (
        <>
            <Headmenu />
            <div className={'container m-auto'}>

                {
                    applicationStatuses && (
                        <div className={'p-5 flex justify-between items-center'}>
                            <div className={'flex justify-between items-center gap-5'}>
                                {
                                    searchParams.get('fs') === String(-1) ? (
                                        <ActivatedButton>Все</ActivatedButton>
                                    ) : (
                                        <Button onClick={() => {
                                            window.location = window.location.href.split('?')[0] + '?fs=' + String(-1)
                                        }}>Все</Button>
                                    )
                                }
                                {
                                    applicationStatuses.map((item) =>
                                        searchParams.get('fs') === String(item.id) ? (
                                            <ActivatedButton>{item.name}</ActivatedButton>
                                        ) : (
                                            <Button onClick={() => {
                                                window.location = window.location.href.split('?')[0] + '?fs=' + String(item.id)
                                            }}>{item.name}</Button>
                                        )
                                    )
                                }
                            </div>
                            <div>
                                <Button onClick={() => {
                                    axiosInstance.get('applications/export/pdf', {
                                        responseType: "blob"
                                    })
                                        .then((response) => {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'applications.pdf'); // Название файла для скачивания
                                            document.body.appendChild(link);
                                            link.click();

                                            // Освобождаем созданный URL после скачивания
                                            window.URL.revokeObjectURL(url);
                                        })
                                        .catch((error) => {
                                            alert('Ошибка при экспорте')
                                        })
                                }}>Экcпорт в PDF</Button>
                            </div>
                        </div>
                    )
                }

                <table className="table-auto w-full">
                    <thead className={'text-start bg-gray-100'}>
                    <tr className={'p-5'}>
                        <th className={'text-start px-5 py-3'}>№</th>
                        <th className={'text-start px-5 py-3'}>Инициатор</th>
                        <th className={'text-start px-5 py-3'}>Дата создания</th>
                        <th className={'text-start px-5 py-3'}>Статус</th>
                        <th className={'text-start px-5 py-3'}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        applicationsData.map(data =>
                            <tr key={data.id}>
                                <td className={'text-start px-5 py-3'}>{data.id}</td>
                                <td className={'text-start px-5 py-3'}>{data.applicant_data}</td>
                                <td className={'text-start px-5 py-3'}>{(new Date(data.created_at)).toLocaleString('ru-ru').split(',')[0]}</td>
                                <td className={'text-start px-5 py-3'}>{data.status.name}</td>
                                <td className={'text-start px-5 py-3'}><a href={`/admin/application/${data.id}`}><i
                                    className='bx bx-menu'></i></a></td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}