export default function AddressInputField({setPointA, setPointB,title, hint}) {
    return (
        <div className={'flex justify-between items-center w-full max-lg:flex-col max-lg:items-start max-lg:gap-2'}>
            <div>
                <p className={'text-lg'}>{title}</p>
                <p className={'text-sm text-white'}>{hint}</p>
            </div>
            <div className={'flex flex-col gap-2 relative max-lg:w-full'}>
                <input type={'text'} className={'p-2 rounded-lg ps-5 pe-5 bg-white w-[300px] max-lg:w-full outline-none transition-all'} placeholder={'Точка A / Место отправления'} onInput={(e) => {setPointA(e.target.value)}} />
                <input type={'text'} className={'p-2 rounded-lg ps-5 pe-5 bg-white w-[300px] max-lg:w-full outline-none transition-all'} placeholder={'Точка Б / Место получения'} onInput={(e) => {setPointB(e.target.value)}} />
            </div>
        </div>
    )
}