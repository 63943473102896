import {useEffect, useState} from "react";

export default function MultiSelectInputField({title, hint, setSource, availableChoices, multiChoice = false}) {

    const [selectedValue, setSelectedValue] = useState([])

    const [isChoicesVisible, setIsChoicesVisible] = useState(false)

    const addValue = (val) => {
        setSelectedValue([...selectedValue, val])
    }

    const removeValue = (val) => {
        setSelectedValue(selectedValue.filter(item => item !== val))
    }

    useEffect(() => {
        setSource(selectedValue)
    }, [selectedValue]);

    return (
        <div className={'flex justify-between items-center w-full max-lg:flex-col max-lg:items-start max-lg:gap-2'}>
            <div>
                <p className={'text-lg'}>{title}</p>
                <p className={'text-sm text-white'}>{hint}</p>
            </div>
            <div className={'max-lg:w-full'}>
                <div className={'p-2 ps-5 pe-5 bg-white w-[300px] max-lg:w-full cursor-pointer transition-all rounded-lg'}
                     onClick={() => setIsChoicesVisible(!isChoicesVisible)}>
                    {
                        selectedValue.length > 0 ? (
                            <p>
                                {selectedValue.join(', ')}
                            </p>
                        ) : (
                            <span className={'text-slate-400'}>
                                Выберите несколько из списка
                            </span>
                        )
                    }
                </div>
                {
                    isChoicesVisible && (
                        <div className={'absolute z-10 p-0 bg-white mt-2 w-[300px] max-lg:w-2/3 rounded-lg flex flex-col shadow-xl'}>
                            {
                                availableChoices.map((item, ind) =>
                                    <div
                                        key={ind}
                                        className={'p-2 hover:bg-slate-200 transition-all cursor-pointer ' + (selectedValue.includes(item.name) ? 'bg-blue-200' : '')}
                                        onClick={() => {
                                            if (selectedValue.includes(item.name)) {
                                                removeValue(item.name)
                                            } else {
                                                addValue(item.name)
                                            }
                                        }}>
                                        <p>{item.name}</p>
                                    </div>
                                )
                            }
                        </div>
                    )

                }
            </div>
        </div>
    )
}