export default function NumberInputField({setSource, title, hint, placeholder}) {
    return (
        <div className={'flex justify-between items-center w-full max-lg:flex-col max-lg:items-start max-lg:gap-2'}>
            <div>
                <p className={'text-lg'}>{title}</p>
                <p className={'text-sm text-white'}>{hint}</p>
            </div>
            <div className={'max-lg:w-full'}>
                <input type={'number'} className={'p-2 ps-5 pe-5 bg-white outline-none transition-all w-[300px] max-lg:w-full rounded-lg'} placeholder={placeholder} onInput={(e) => {setSource(e.target.value)}} />
            </div>
        </div>
    )
}