import TextInputField from "../components/TextInputField";
import {useState} from "react";
import Button from "../components/Button";
import axiosInstance from "../api/axiosInstance";
import Headmenu from "../components/headmenu";

export default function AdminPanelLogin() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("")

    const LoginUser = () => {
        setError("")
        axiosInstance.post("/auth/token/", {
            username: email,
            password: password,
        })
            .then((response) => {
                localStorage.setItem('access', response.data.access);
                localStorage.setItem('refresh', response.data.refresh);
                window.location = '/admin/list'
            })
            .catch((error) => {
                let data = error.response.data
                let firstErrorKey = Object.keys(data)[0]
                let loc = ""
                if (firstErrorKey === "username") loc = "Email"
                if (firstErrorKey === "password") loc = "Пароль"
                if (firstErrorKey === "detail") {
                    loc = "Ошибка"
                    data[firstErrorKey] = "Неверный логин или пароль"
                }

                setError(`${loc}: ${data[firstErrorKey]}`)
            })
    }

    return (
        <>
            <Headmenu />
            <div className={'container m-auto h-svh'}>
                <div className={'p-10 flex flex-col gap-5 w-1/2 max-lg:w-full m-auto mt-20'}>
                    <p className={'text-3xl'}>Авторизация</p>

                    <div className={'flex flex-col gap-5'}>
                        <TextInputField placeholder={'Введите Email'} title={'Email'} setSource={setEmail}/>
                        <TextInputField secret={true} placeholder={'Введите Пароль'} title={'Пароль'}
                                        setSource={setPassword}/>
                        <Button onClick={LoginUser}>Войти</Button>
                    </div>

                    <p className={'text-red-500'}>{error}</p>
                </div>
            </div>
        </>
    )
}